/* eslint-disable no-unused-vars */
import {html, PolymerElement} from "@polymer/polymer/polymer-element";

import "@polymer/paper-input/paper-input";
import "@polymer/paper-icon-button/paper-icon-button";
import moment from "moment-timezone";

/**
 * `findpassword-dialog-container`
 *
 *
 * @customElement
 * @polymer
 */
class findpasswordDialogContainer extends PolymerElement {
   static get template() {
      return html`
      <style>
         paper-icon-button {
             width: 50px;
             height: 50px;
             float: right;
             color: #aaaaaa;
         }
         paper-input {
             --paper-input-container-focus-color: #0087cb;
             --paper-input-container-input: {
                 color: #aaa;
             };
             width: 80%;
         }
         #okBtn, #checkBtn {
             background: #0087cb;
         }

         #cancelBtn {
             background: #4c5667;
         }
         .btn {
             width: 122px;
             height: 35px;
             border: 0;
             font-size: 12pt;
             color: #ffffff;
             cursor: pointer;
             border-radius: 2px;
             margin: 10px;
         }
         .findpassword-layout {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            height: calc(100% - 130px);
         }
         .button-layout {
            display: flex;
            /*align-items: center;*/
            justify-content: center;
            width: 100%;
            height: 130px;
         }

         #findpasswordDialog {
             background: #2d333f;
             width: 480px;
             height: 390px;
         }
         .container {
             display: flex;
             flex-direction: column;
             height: 100%;
             width: 100%;
             margin: 0;
             padding: 0;
         }
         .findpassword-title {
             background-color: #252934;
             height: 50px;
             width: 100%;
             color: #fff;
             border-bottom: 1px solid #4c5667;
         }
         .findpassword-container {
             width: 100%;
             height: 100%;
         }
         .findpassword-message {
            color: #E74C3C;
            width: 80%;
            font-size: 0.9em;
         }
         .finally-message {
            width: 80%;
            text-align: center;
         }
      </style>

      <paper-dialog id="findpasswordDialog" modal>
         <div class="container">
            <div class="findpassword-title">
               <p style="margin: 0; padding: 15px; float: left;">Find Password</p>
               <paper-icon-button on-click="doClose" icon="healthhub:close"></paper-icon-button>
            </div>
            <div id="findpasswordContainer" class="findpassword-container">
               <div class="findpassword-layout">
                  <template is="dom-if" if="{{checkStep(_step, 1)}}">
                     <paper-input id="userEmail" label="Please enter your email address" value="{{userEmail}}" autofocus></paper-input>
                  </template>
                  <template is="dom-if" if="{{checkStep(_step, 2)}}">ㅇ
                     <paper-input id="userVerificationCode" label="Enter the Code within 10 minutes" value="{{userVerificationCode}}" autofocus></paper-input>
                  </template>
                  <template is="dom-if" if="{{checkStep(_step, 3)}}">
                     <paper-input id="newPassword" type="password" label="New password" value="{{newPassword}}" on-keyup="checkPassword" autofocus></paper-input>
                     <paper-input id="confirmPassword" type="password" label="Confirm password" value="{{confirmPassword}}" on-keyup="checkPassword"></paper-input>
                  </template>
                  <template is="dom-if" if="{{checkStep(_step, 4)}}">
                     <div class="finally-message">
                        <div style="color: #fff; font-size: 2em; padding: 10px;">{{_userInfo.name}}</div>
                        <div style="color: #aaa; font-size: 1.2em; padding: 10px;">Your password has been changed successfully.</div>
                     </div>
                  </template>
                  <div class="findpassword-message">{{_message}}</div>
               </div>
               <div class="button-layout">
                  <template is="dom-if" if="{{checkStep(_step, 1)}}">
                     <button id="okBtn" class="btn" on-click="sendEmail">Submit</button>
                     <button id="cancelBtn" class="btn" on-click="doClose">Cancel</button>
                  </template>
                  <template is="dom-if" if="{{checkStep(_step, 2)}}">
                    <button id="checkBtn" class="btn" on-click="checkVerificationCode">Verify</button>
                    <button id="cancelBtn" class="btn" on-click="doClose">Cancel</button>
                  </template>
                  <template is="dom-if" if="{{checkStep(_step, 3)}}">
                    <button id="checkBtn" class="btn" on-click="changePassword" style="width: 150px;">Change password</button>
                    <button id="cancelBtn" class="btn" on-click="doClose">Cancel</button>
                  </template>
                  <template is="dom-if" if="{{checkStep(_step, 4)}}">
                    <button id="cancelBtn" class="btn" on-click="doClose">Close</button>
                  </template>
               </div>
            </div>
         </div>
      </paper-dialog>
      `;
   }

   static get properties() {
      return {
         _userInfo: {
            type: Object,
            value: {}
         },
         userEmail: {
            type: String,
            value: null
         },
         userVerificationCode: {
            type: Number,
            value: null
         },
         newPassword: {
            type: String,
            value: null
         },
         confirmPassword: {
            type: String,
            value: null
         },
         _message: {
            type: String,
            value: null
         },
         _step: {
            type: Number,
            value: 1
         }
      };
   }

   static get is() {
      return "findpassword-dialog-container";
   }


   ready() {
      super.ready();
   }

   /**
    * function doClose
    * 비밀번호 찾기 창 닫기
    *
    * Create by 김민정 on 2018-06-27 오후 3:52
    * @param {}
    * @return {}
    */
   doClose() {
      this.clearFindpassword();
      this.$.findpasswordDialog.close();
   }

   /**
    * function callFindpasswordDialog
    * 비밀번호 찾기 창 호출
    *
    * Create by 김민정 on 2018-06-27 오후 3:53
    * @param {}
    * @return {}
    */
   callFindpasswordDialog() {
      this.clearFindpassword();
      this.$.findpasswordDialog.open();
   }

   /**
    * function clearFindpassword
    * 비밀번호 찾기 창 초기화
    *
    * Create by 김민정 on 2018-06-27 오후 3:53
    * @param {}
    * @return {}
    */
   clearFindpassword() {
      this._userInfo = {};
      this.userEmail = null;
      this.userVerificationCode = null;
      this.newPassword = null;
      this.confirmPassword = null;
      this._message = null;
      this._step = 1;
   }

   /**
    * function clearMessage
    * 메세지 초기화
    */
   clearMessage() {
      this._message = null;
   }

   /**
    * function checkFindpassword
    * 비밀번호 조회
    *
    * Create by 김민정 on 2018-06-27 오후 3:54
    * @param {}
    * @return {}
    */
   checkFindpassword() {
      // 비밀번호 찾기
   }

   /**
    * Send Email
    */
   sendEmail() {
      if(!this.userEmail) {
         this._message   = "Please enter your email address.";
         return;
      }

      this.utcCheck().then((timeOffset) => {
         fetch(`/api/user/findPassword/sendEmail?email=${this.userEmail}&timeOffset=${timeOffset}`, {
            method: "GET",
            headers: {
               "Content-Type": "application/json"
            }
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((httpResponse) => {
                  this._userInfo = httpResponse;// JSON.parse(httpResponse);
                  this.clearMessage();
                  this._step = 2;
               });
            } else if(response.status === 204) {
               this._message = "Couldn't find your HPACS account.";
            } else {
               response.json().then((httpResponse) => {
                  const {subErrors, message} = httpResponse;
                  if(subErrors && subErrors.length > 0) {
                     this._message = subErrors[0].message;
                  } else if(message) {
                     this._message = message;
                  }
               });
            }
         }).catch((error) => {
            console.log("error", error);
         });
      });
   }

   /**
    * Check Verification Code
    */
   checkVerificationCode() {
      if(!this.userVerificationCode) {
         this._message = "Please enter verification code.";
         return;
      }

      fetch(`/api/user/findPassword/checkVerificationCode?email=${this.userEmail}&verificationCode=${this.userVerificationCode}`, {
         method: "GET",
         headers: {
            "Content-Type": "application/json"
         }
      }).then((response) => {
         if (response.ok && response.status === 200) {
            response.json().then((httpResponse) => {
               const userPassword = httpResponse;// JSON.parse(httpResponse);
               this.clearMessage();
               this._step = 3;
            });
         } else if(response.status === 204) {
            this._message = "Wrong code. Try again.";
         } else {
            response.json().then((httpResponse) => {
               const {subErrors, message} = httpResponse;
               if(subErrors && subErrors.length > 0) {
                  this._message = subErrors[0].message;
               } else if(message) {
                  this._message = message;
               }
            });
         }
      }).catch((error) => {
         console.log("error", error);
      });
   }

   /**
    * Change Password
    */
   changePassword() {
      if(!this.newPassword || !this.confirmPassword) {
         this._message = "please enter a new password.";
         return;
      }

      if(this.checkPassword()) {
         fetch(`/api/users/${this._userInfo.id}/password/change`, {
            method: "PUT",
            headers: {
               "Content-Type": "application/json"
            },
            body: JSON.stringify({newPassword: this.newPassword})
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((httpResponse) => {
                  const isChangeUserPassword = httpResponse;// JSON.parse(httpResponse);
                  if(isChangeUserPassword) {
                     this.clearMessage();
                     this._step = 4;
                  }
               });
            } else {
               response.json().then((httpResponse) => {
                  const {subErrors, message} = httpResponse;
                  if(subErrors && subErrors.length > 0) {
                     this._message = subErrors[0].message;
                  } else if(message) {
                     this._message = message;
                  }
               });
            }
         }).catch((error) => {
            console.log("error", error);
         });
      }
   }

   /**
    * Check Step
    * @param step
    * @param checkStep
    * @returns {boolean}
    */
   checkStep(step, checkStep) {
      return step === checkStep;
   }

   /**
    * Check Password
    * @returns {boolean}
    */
   checkPassword() {
      if(this.confirmPassword && this.newPassword !== this.confirmPassword) {
         this._message = "Those passwords didn't match. Try again.";
         return false;
      }
      this._message = null;
      return true;
   }

   utcCheck() {
      return new Promise((resolve) => {
         const now = new Date();
         const _zone = moment.tz.guess();
         const timeOffset = moment(now.getTime()).tz(_zone);
         resolve(timeOffset._offset);
      });
   }
}

window.customElements.define(findpasswordDialogContainer.is, findpasswordDialogContainer);
