/* eslint-disable no-undef */
/* eslint-disable no-return-assign,prefer-destructuring */
import {html, PolymerElement} from "@polymer/polymer/polymer-element";
import "@polymer/paper-input/paper-input";
import "@polymer/paper-button/paper-button";
// import "@polymer/iron-ajax/iron-ajax";
import "@polymer/paper-checkbox/paper-checkbox";
import "@polymer/paper-dialog/paper-dialog";
import "@polymer/iron-input/iron-input";
import "@polymer/iron-icons/iron-icons";
import "@polymer/iron-image/iron-image";
import "@polymer/paper-toast/paper-toast";

import "@hpacs/healthhub-icons/healthhub-icons";

import moment from "moment-timezone";

import "./dialog/findemail-dialog-container";
import "./dialog/findpassword-dialog-container";

/**
 * `hh-login`
 *
 *
 * @customElement
 * @polymer
 */
class HhLogin extends PolymerElement {
   static get template() {
      return html`
      <style>
         :host {
            display: block;
         }

         form {
            margin: 0px;
            padding: 0px;
         }

         paper-button {
            font-family: NotoSansCJK, NotoSansCJKLight !important;
            color: #aaaaaa;
            font-size: 12px;
         }
         paper-button:hover {
            color: #0087cb;
         }

         #signInBtn {
            font-family: NotoSansCJKDemi, NotoSansCJK, NotoSansCJKLight !important;
            font-size: 10pt;
            background: #0087cb;
            width: 130px;
            height: 35px;
            color: #ffffff;
            border-radius: 2px;
            border: 0px;
            cursor: pointer;
         }

         paper-checkbox {
            --paper-checkbox-label-color: #aaaaaa;
            --paper-checkbox-checked-color: #4c5667;
            --paper-checkbox-label: {
               font-size: 12px;
               font-family: NotoSansCJKDemi, NotoSansCJK, NotoSansCJKLight !important;
            }
         }

         .lebel {
            font-family: NotoSansCJKDemi, NotoSansCJK, NotoSansCJKLight !important;
            color: #aaaaaa;
            font-size: 12px;
         }

         .lebel-sub {
            font-family: NotoSansCJK, NotoSansCJKLight !important;
            color: #aaaaaa;
            font-size: 12px;
         }

         .link-without-underline {
            color: #aaaaaa;
            text-decoration: none;
            padding-left: 10px;
            padding-right: 10px;
         }

         .inputInfo {
            width: 400px;
            height: 35px;
            border-radius: 2px;
            background-color: #2d333f;
            color: #4c5667;
            border: 1px solid;
            font-size: 12pt;
            font-family: NotoSansCJKDemi, NotoSansCJK, NotoSansCJKLight !important;
         }

         #chkRemember {
            --paper-checkbox-unchecked-color: #4c5667;
         }

         .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 380px;
            width: 470px;
            background: #2d333f;
         }

         .container-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
         }

         .fixed-child-line {
            width: 470px;
            height: 5px;
            background-color: #0087cd;
         }

         .fixed-child-logo {
            width: 470px;
            height: 67px;
            background-color: #1d2025;
         }

         /*.fixed-child-logo img {*/
         /*width: 230px;*/
         /*margin-top: 13px;*/
         /*margin-left: 13px;*/
         /*}*/

         .fixed-child-email {
            margin-top: 20px;
            width: 100%;
         }

         .fixed-child-password {
            margin-top: 5px;
            width: 100%;
         }

         .fixed-child-signin {
            margin-top: 45px;
            width: 404px;
         }

         .fixed-child-find {
            margin-top: 35px;
            /*margin-bottom: 25px;*/
         }

         p {
            padding: 0;
            margin: 0;
         }

         iron-image {
            width: 230px;
            height: 48px;
            margin-top: 10px;
            /* margin-left: -15px; */
            margin-left: 15px;
         }

         iron-icon, div[suffix] {
            color: hsl(0, 0%, 50%);
            margin-right: 12px;
         }

         paper-input {
            max-width: 400px;
            margin: auto;
            --paper-input-container-input-color: #aaa;
            --paper-input-container-focus-color: #0087cb;
         }

         paper-toast {
            --paper-toast-color: white;
         }

         #toast {
            --paper-toast-background-color: #0087cb;
         }
      </style>

      <paper-toast id="toastAlert" text=""></paper-toast>
      <paper-toast id="toast" duration="0" class="fit-bottom" text="DICOM Structure prefetching..."></paper-toast>

      <div>
         <!--<paper-button raised on-click="openSignInDialog">Sign in</paper-button>--> <!--버튼 삭제-->
         <paper-dialog id="signInDialog" modal noCancelOnOutsideClick>
            <form id="loginForm">
               <div class="container">
                  <div class="fixed-child-logo">
                     <iron-image sizing="contain" src="/resource/images/ci/new_logo_hpacs.png"></iron-image>
                  </div>
                  <div class="fixed-child-line"></div>

                  <div class="fixed-child-email">
                     <paper-input id="email" label="Please enter your email address" value="{{email::input}}">
                        <iron-icon icon="mail" slot="prefix"></iron-icon>
                     </paper-input>
                  </div>
                  <div class="fixed-child-password">
                     <paper-input id="password" label="Please enter your password" type="password" value="{{password::input}}">
                        <iron-icon icon="https" slot="prefix"></iron-icon>
                     </paper-input>
                  </div>
                  <div class="fixed-child-signin">
                     <div class="container-row">
                        <div class="flex-child">
                           <paper-checkbox id="chkRemember" checked="{{rememberChecked}}">Remember me
                           </paper-checkbox>
                        </div>
                        <div class="flex-child">
                           <input id="signInBtn" type="submit" value="Sign in">
                        </div>
                     </div>
                  </div>
                  <div class="fixed-child-find">
                     <!--<p class="lebel-sub">-->
                     <!--<a href="#" class="link-without-underline">Find email</a>-->
                     <!--| <a href="#" class="link-without-underline">Find password</a>-->
                     <!--&lt;!&ndash;| <a href="#" class="link-without-underline" style="color:#0087cb">Sign up</a>&ndash;&gt;-->
                     <!--</p>-->

                     <template is="dom-if" if="{{isPopup}}">
                        <paper-button id="goHomeBtn" on-click="goHome">Go Home</paper-button>
                     </template>
                     <template is="dom-if" if="{{!isPopup}}">
                        <paper-button id="findEmailBtn" on-click="popupFindEmailDialog">Find email</paper-button>
                     </template>
                     <paper-button id="findPasswordBtn" on-click="popupFindPasswordDialog">Find password</paper-button>

                  </div>
               </div>
            </form>
         </paper-dialog>
      </div>

      <findemail-dialog-container id="findEmailDialogContainer"></findemail-dialog-container>
      <findpassword-dialog-container id="findPasswordDialogContainer"></findpassword-dialog-container>
      `;
   }

   static get properties() {
      return {
         email: {
            type: String,
            value: "",
            observer: "observeEmail",
            notify: true,
            readOnly: false
         },
         password: {
            type: String,
            value: ""
         },
         rememberChecked: Boolean,
         savedEmailKey: { // 이메일을 저장할 로컬스토리지의 키 이름, 외부에서 접근할 수 있게 하는 것이 나을 것 같아서 Polymer properties 로 설정하고 값을 전달하게 하였음
            type: String,
            value: "rememberEmail"
         },
         _message: {
            type: Object,
            value() {
               return {
                  msg: "",
                  isErr: false
               };
            }
         },
         isPopup: {
            type: Boolean,
            value: false
         },
         offset: Number
      };
   }

   static get is() {
      return "hh-login";
   }

   ready() {
      super.ready();

      this.$.loginForm.addEventListener("submit", (event) => {
         event.preventDefault();
         this.doLogin();
         return false;
      });

      this.$.email.addEventListener("keyup",(e) => {
         if(e.keyCode === 13){
            this.doLogin();
         }
      });

      this.$.password.addEventListener("keyup", (e) => {
         if(e.keyCode === 13){
            this.doLogin();
         }
      });

      this.$.findEmailDialogContainer.addEventListener("toastEvent", (e) => {
         const message = e.detail;

         this.openToast(message);
      });

      this.offset = moment().utcOffset();
   }

   open(isPopup = false) {
      this.isPopup = isPopup;

      this.checkByEmail(); // 이메일이 저장되어 있으면 체크표시를 하고, 없으면 체크표시를 안한다.
      this.$.signInDialog.open();

      setTimeout(() => {
         if(this.$.email.value === "") {
            this.$.email.focus();
         } else {
            this.$.password.focus();
         }
      }, 0);
   }

   close() {
      this.clear();
      this.$.signInDialog.close();
   }

   clear() {
      this.email = "";
      this.password = "";
   }

   observeEmail() {
      // console.log(email);
   }

   openSignInDialog() {
      this.$.signInDialog.open();
   }

   doLogin() {
      // const ajax = this.$.ajax;
      if(this.email){
         if(!this.isEmail(this.email)){
            this._message.msg = "The value entered is not in email format";
            this._message.isErr = true;
            this.openToast(this._message);
            return;
         }
      }
      if (!this.email || !this.password) {
         this._message.msg = "you may missed email or password";
         this._message.isErr = true;
         this.openToast(this._message);
         // alert("you may missed email or password");
         return;
      }

      fetch("/api/auth/login/v1", {
      // fetch("/login", {
         method: "POST",
         headers: {
            "Content-Type": "application/json"
         },
         body: JSON.stringify({
            email: this.email,
            password: this.password
         })
      }).then((response) => {
         if(response.ok && response.status === 200) {
            response.json().then((httpResponse) => {
               this.saveUserInfo(httpResponse); // 사용자 정보를 세션 스토리지에 저장
               this.addOrRemoveEmail(); // 이메일의 정보를 로컬 스토리지에 추가/삭제한다.
               // this.saveWebSession(httpResponse);

               if(httpResponse.doCert) {
                  localStorage.setItem(__CERT_STORAGE__, JSON.stringify(true));
               } else {
                  localStorage.removeItem(__CERT_STORAGE__);
               }

               // if(window.location.pathname === "/")
               if(this.isPopup) {
                  this.dispatchEvent(new CustomEvent("checkExpTime", {bubbles: true, composed: true }));
                  this.close();
               } else {
                  window.location.href = "/";
               }

            });
         } else {
            response.json().then((json) => {
               this.openToast({ msg : `Failed to sign in, check your information. [${json.message}]`, isErr : true });
            });
            // alert("Failed to sign in, check your information");
         }
      }).catch(err => this.openToast({msg: err.message, isErr: true}));
   }

   /**
    * 입력한 메일의 형식을 확인함
    *
    */
   isEmail(email){
      // eslint-disable-next-line no-useless-escape
      const regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
      return regExp.test(email);
   }

   /**
    * 로그인한 사용자의 정보를 sessionStorage에 저장한다.
    * @param response
    */
   saveUserInfo(response) {
      const userInfo = {};
      userInfo.id = response.user.id;
      userInfo.email = response.user.email;
      userInfo.engName = response.user.engName;
      userInfo.name = response.user.name;
      userInfo.facilityName = response.user.facilityName;
      userInfo.isScp = response.user.isScp;
      userInfo.activeAi = response.activeAi;
      userInfo.activeHscan = response.activeHscan;
      userInfo.activeHrefer = response.activeHrefer;
      userInfo.doCert = response.doCert;

      if(response.user.facility.length > 1) {
         // eslint-disable-next-line prefer-destructuring
         userInfo.facilityId = response.user.facility[1];
      } else {
         // eslint-disable-next-line prefer-destructuring
         userInfo.facilityId = response.user.facility[0];
      }
      userInfo.licenseNo = response.user.licenseNo;
      userInfo.isRefer = response.isRefer;

      if(!response.user.roles) {
         userInfo.roles = [];
      }
      else {
         userInfo.roles = response.user.roles;
      }

      localStorage.setItem("user", JSON.stringify(userInfo));
      localStorage.setItem("jwt", response.jwt);
      localStorage.setItem("jwtExpTime", response.jwtExpTime);
   }

   /**
    * 이메일의 정보를 로컬 스토리지에 추가/삭제한다.
    */
   addOrRemoveEmail() {
      if (this.rememberChecked) {
         localStorage.setItem(this.savedEmailKey, this.email);
      } else {
         localStorage.removeItem(this.savedEmailKey);
      }
   }

   /**
    * 이메일이 저장되어 있으면 체크표시를 하고, 없으면 체크표시를 안한다.
    */
   checkByEmail() {
      const rememberEmail = localStorage.getItem(this.savedEmailKey);
      if (this.isPopup) {
         // 메인 화면에서 뜨는 팝업의 경우 현재 로그인 되어있던 이메일을 고정으로 하고 변경할 수 없도록 함.
         const currentEmail = JSON.parse(localStorage.getItem("user")).email;
         this.email = currentEmail;
         this.$.email.autofocus = false;
         this.$.email.readonly = true;
         this.$.password.autofocus = true;
         if (currentEmail === rememberEmail)
            this.rememberChecked = true;
         else
            this.rememberChecked = false;
      } else if (rememberEmail) {
         this.email = rememberEmail;
         this.$.email.autofocus = false;
         this.$.email.readonly = false;
         this.$.password.autofocus = true;
         this.rememberChecked = true;
      } else {
         this.$.email.autofocus = true;
         this.$.email.readonly = false;
         this.rememberChecked = false;
      }
   }

   /**
    * function popupFindEmailDialog
    * 이메일 찾기 창 호출
    *
    * Create by 김민정 on 2018-06-27 오후 4:22
    * @param {}
    * @return {}
    */
   popupFindEmailDialog() {
      this.$.findEmailDialogContainer.callFindemailDialog();
   }

   /**
    * function popupFindPasswordDialog
    * 비밀번호 찾기 창 호출
    *
    * Create by 김민정 on 2018-06-27 오후 4:23
    * @param {}
    * @return {}
    */
   popupFindPasswordDialog() {
      this.$.findPasswordDialogContainer.callFindpasswordDialog();
   }

   /**
    * function openToast
    * toast
    *
    * Create by 김민정 on 2018-08-28 오후 2:09
    * @param {}
    * @return {}
    */
   openToast(message) {
      if (!message.isErr) {
         this.$.toastAlert.style.backgroundColor = "#0087cb";
      } else {
         this.$.toastAlert.style.backgroundColor = "#e46159";
      }

      this.$.toastAlert.text = message.msg;
      this.$.toastAlert.open();
   }

   goHome() {
      if(window.filmbox && window.filmbox.get()) {
         // #17034 토큰이 만료된 후 goHome 버튼 눌렀을 경우 viewing 정보 update
         const hash = window.filmbox.get().location.hash.substr(1);
         const id = hash.split("&")[0];
         this.updateFilmboxOFFStatusByObjectIdUser(id).then(() => {
            window.location.href = "/home";
         });
      } else {
         window.location.href = "/home";
      }
   }

   updateFilmboxOFFStatusByObjectIdUser(requestObjectId) {
      const id = JSON.parse(localStorage.getItem("user")).id;
      return new Promise((resolve) => {
         fetch(`/api/exchange/worklist/filmboxStatus/close/${requestObjectId}/user?userId=${id}`, {
            method: "PATCH",
            headers: {
               "Content-Type": "application/json"
            }
         }).then((response) => {
            if (!response.ok) {
               console.error("Message : ", response.status);
            }
         }).finally(() => resolve());
      });
   }
}

window.customElements.define(HhLogin.is, HhLogin);
