import {html, PolymerElement} from "@polymer/polymer/polymer-element";
// import "@polymer/iron-ajax/iron-ajax";
import "@polymer/paper-input/paper-input";
import "@polymer/paper-icon-button/paper-icon-button";

/**
 * `findemail-dialog-container`
 *
 *
 * @customElement
 * @polymer
 */
class FindemailDialogContainer extends PolymerElement {
   static get template() {
      return html`
      <style>
         paper-icon-button {
             width: 50px;
             height: 50px;
             float: right;
             color: #aaaaaa;
         }
         paper-input {
             --paper-input-container-focus-color: #0087cb;
             --paper-input-container-input: {
                 color: #aaa;
             };
             margin: auto;
         }
         #findBtn {
             background: #0087cb;
         }
         #cancelBtn {
             background: #4c5667;
         }
         .inputContainer {
             display: flex;
         }
         .inputText {
             padding: 20px 25px;
             flex: 1;
         }
         .findBtnContainer {
             text-align: right;
             padding: 0 25px;
         }
         .btn {
             width: 122px;
             height: 35px;
             border: 0;
             font-size: 12pt;
             color: #ffffff;
             cursor: pointer;
             border-radius: 2px;
         }
         .classA {
             text-align: center;
             padding: 25px;
         }
         #findemailDialog {
             background: #2d333f;
             width: 480px;
             height: 390px;
         }
         .container {
             display: flex;
             flex-direction: column;
             height: 100%;
             width: 100%;
             margin: 0;
             padding: 0;
         }
         .findemail-title {
             background-color: #252934;
             height: 50px;
             width: 100%;
             color: #fff;
             border-bottom: 1px solid #4c5667;
         }
         .findemail-container {
             width: 100%;
             height: 100%;
         }
      </style>

      <paper-dialog id="findemailDialog" modal>
<!--         <iron-ajax id="ajax"></iron-ajax>-->
         <div class="container">
            <div class="findemail-title">
               <p style="margin: 0; padding: 15px; float: left;">Find Email</p>
               <paper-icon-button on-click="doClose" icon="healthhub:close"></paper-icon-button>
            </div>
            <div class="findemail-container">
               <div class="inputContainer">
                  <div class="inputText">
                     <paper-input id="userInfoName" label="User Name" value="{{userInfoName}}"></paper-input>
                  </div>
                  <div class="inputText">
                     <paper-input id="userInfoPhoneNo" label="User PhoneNo" type="contact" value="{{userInfoPhoneNo}}"></paper-input>
                  </div>
               </div>
               <div class="findBtnContainer">
                  <button id="findBtn" class="btn" on-click="doFind">Find</button>
               </div>
               <div class="classA">
                  <paper-input always-float-label id="userFindEmail" label="Your Email" value="{{userFindEmail}}" readonly style="margin: auto;"></paper-input>
               </div>
               <div class="classA">
                  <button id="cancelBtn" class="btn" on-click="doClose">Cancel</button>
               </div>
            </div>
         </div>
      </paper-dialog>
      `;
   }

   static get properties() {
      return {
         userInfoName: {
            type: String,
            value: ""
         },
         userInfoPhoneNo: {
            type: String,
            value: ""
         },
         userFindEmail: {
            type: String,
            value: ""
         },
         _message: {
            type: Object,
            value() {
               return {
                  msg: "",
                  isErr: false
               };
            }
         }
      };
   }

   static get is() {
      return "findemail-dialog-container";
   }


   ready() {
      super.ready();
   }

   /**
    * function doFind
    * 이메일 찾기 버튼 실행
    *
    * Create by 김민정 on 2018-07-09 오후 3:15
    * @param {}
    * @return {}
    */
   doFind() {
      if(this.userInfoName === "" || this.userInfoPhoneNo === "") {
         this._message.msg = "please check your name or phone number";
         this._message.isErr = true;

         const param = {};
         param.detail = this._message;
         this.dispatchEvent(new CustomEvent("toastEvent", param));
      }
      else {
         this.getUserByFindUser();
      }
   }

   /**
    * function getUserByFindUser
    * 사용자 정보 조회
    *
    * Create by 김민정 on 2018-07-09 오후 5:03
    * @param {}
    * @return {}
    */
   getUserByFindUser() {
      // const ajax = this.$.ajax;
      fetch(`/api/user/userInfo/userFindUser?name=${this.userInfoName}&phoneNo=${this.userInfoPhoneNo}`, {
         method: "GET",
         headers: {
            "Content-Type": "application/json"
         }
      }).then((response) => {
         if (response.ok && response.status === 200) {
            response.json().then((httpResponse) => {
               const userInfo = httpResponse;// JSON.parse(httpResponse);
               this.$.userFindEmail.value = userInfo.email;
               // 로그인 버튼 생성
            });
         } else {
            this._message.msg = "please check your name or phone number";
            this._message.isErr = true;

            const param = {};
            param.detail = this._message;
            this.dispatchEvent(new CustomEvent("toastEvent", param));

            this.$.userInfoName.value = "";
            this.$.userInfoPhoneNo.value = "";
         }
      });
      // this.$.ajax.url = "/userFindUser/".concat(this.userInfoName).concat("/").concat(this.userInfoPhoneNo);
      // this.$.ajax.method = "get";
      // this.$.ajax.contentType = "application/json";
      // this.$.ajax.generateRequest().completes.then((a) => {
      //    const {response} = a;
      //
      //    if (response.success) {
      //       const userInfo = JSON.parse(response.result);
      //       this.$.userFindEmail.value = userInfo.email;
      //       // 로그인 버튼 생성
      //    } else {
      //       this._message.msg = "please check your name or phone number";
      //       this._message.isErr = true;
      //
      //       const param = {};
      //       param.detail = this._message;
      //       this.dispatchEvent(new CustomEvent("toastEvent", param));
      //
      //       this.$.userInfoName.value = "";
      //       this.$.userInfoPhoneNo.value = "";
      //    }
      // });
   }

   /**
    * function doClose
    * 이메일 찾기 창 닫기
    *
    * Create by 김민정 on 2018-06-27 오후 3:49
    * @param {}
    * @return {}
    */
   doClose() {
      this.$.findemailDialog.close();
   }

   /**
    * function callFindemailDialog
    * 이메일 찾기 창 호출
    *
    * Create by 김민정 on 2018-06-27 오후 3:50
    * @param {}
    * @return {}
    */
   callFindemailDialog() {
      this.clearFindemailDialog();
      this.$.findemailDialog.open();
   }

   /**
    * function clearFindemailDialog
    * 이메일 찾기 창 초기화
    *
    * Create by 김민정 on 2018-06-27 오후 3:51
    * @param {}
    * @return {}
    */
   clearFindemailDialog() {
      this.$.userInfoName.value = "";
      this.$.userInfoPhoneNo.value = "";
      this.$.userFindEmail.value = "";
   }
}

window.customElements.define(FindemailDialogContainer.is, FindemailDialogContainer);
